@tailwind base;
@tailwind components;
@tailwind utilities;
/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
$primary: theme('colors.primary');
$bg-card: var(--fuse-bg-card);


drawer-router-outlet {
    width: 100%;
    height: 100%;
}

.drawer-container {
    height: 100%;
}
  
.drawer-sidenav-content {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.m-0-content .mat-mdc-tab-label-container
{
    margin: 0px!important;
}
.p-0-content .mat-mdc-tab-body-content
{
    padding: 0px!important;
}
.mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0px!important;
}

// Spinner icon
.icn-spinner {
    animation: spin-animation 1s infinite;
    animation-timing-function: linear;
    display: inline-block;
    color: $primary;
}
.float-right{
    float: right;
}

@keyframes spin-animation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

// Fast view 
#fast-view {
    position: fixed;
    width: 350px;
    max-width: 100%;
    z-index: 100000;
    .arrow-sign {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid $bg-card;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        clear: both;
        overflow: hidden;
    }
}

.disable-selection {
    -webkit-user-select: none !important; /* Chrome, Safari, Opera */
    -moz-user-select: none !important; /* Firefox */
    -ms-user-select: none !important; /* Internet Explorer/Edge */
    user-select: none !important; /* Standard syntax */
}

button.date-available {
    background: rgb(187, 247, 208,0.5);
    border-radius: 100%;
}
button.date-unavailable {
    background: rgb(254, 202, 202,0.5);
    border-radius: 100%;
}

.pricing-modal {
    width:  95%;
    height: 95%;
    max-width:  95%;
}

.full-screen-pricing{
    overflow-x: hidden;
    display: initial;
    height: 100%;
}

.mat-mdc-tab-body-wrapper {
    height: 100%;
}

.highlight-date {
    background-color: rgb(239 68 68) !important; /* Light green background */
    color:white !important;
    border-radius: 50%; /* Optional: Makes the background round like the default material date styling */
  }

@media (max-width: 750px) {
    .mobile-calendar-responsive-dialog {
        border-radius: 0px !important;
        width:100% !important;
        max-width: 100% !important;
        height:100% !important;
    }
}

.max-h-70vh{
    max-height: 70vh;
}

.ql-snow .ql-editor img {
    max-width: 100%;
    width: auto;
}